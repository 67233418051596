<template>
  <el-main>
    <el-form :model="addForm" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="addForm.sort"></el-input>
      </el-form-item>
      <el-form-item label="课程图片：" prop="image">
        <ReadyUploadSource @getSource="val => (addForm.image = val.path)" :path="addForm.image" @removeThis="() => (addForm.image = '')"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="课程分类：" prop="cate_id">
        <el-select clearable v-model="addForm.cate_id" placeholder="请选择">
          <el-option v-for="item in courseClassify" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程标题：" prop="title">
        <el-input v-model="addForm.title"></el-input>
      </el-form-item>
      <el-form-item label="课程费用：" prop="price">
        <el-input v-model="addForm.price" placeholder="填写0元或不填表示免费"></el-input>
      </el-form-item>
      <el-form-item label="虚拟人数：" prop="virtual_num">
        <el-input v-model="addForm.virtual_num"></el-input>
      </el-form-item>
      <el-form-item label="是否推荐：">
        <el-radio-group v-model="addForm.recommended">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-radio-group v-model="addForm.status">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="课程简介：" prop="content">
        <RichText :richTxt="addForm.content" @soninfo="val => (addForm.content = val)"></RichText>
      </el-form-item>
    </el-form>
    <Preservation @preservation="submit">
      <router-link to="/beauty/curriculum/list" slot="return" v-if="id" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
export default {
  components: {
    ReadyUploadSource,
    RichText,
    Preservation,
  },
  data() {
    return {
      courseClassify: [],
      addForm: {
        image: '',
        title: '',
        sort: '',
        price: '',
        virtual_num: '',
        cate_id: '',
        recommended: 0,
        content: '',
        status: 1,
      },
      rules: {
        image: { required: true, message: '请添加课程图片', trigger: 'blur' },
        title: { required: true, message: '请填写课程标题', trigger: 'blur' },
        sort: { required: true, message: '请填写课程排序', trigger: 'blur' },
        price: { required: true, message: '请填写课程价格', trigger: 'blur' },
        virtual_num: { required: true, message: '请填写虚拟人数', trigger: 'blur' },
        content: { required: true, message: '请填写课程简介', trigger: 'blur' },
        cate_id: { required: true, message: '请选择课程分类', trigger: 'blur' },
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
    this.$axios
      .post(this.$api.beauty.courseCateList, {
        rows: 1000,
      })
      .then(res => {
        if (res.code == 0) {
          this.courseClassify = res.result.list;
        }
      });
  },
  methods: {
    getInfo() {
      this.$axios
        .post(this.$api.beauty.courseInfo, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
            let info = res.result;
            this.addForm.cate_id = info.cate_id;
            this.addForm.content = info.content;
            this.addForm.title = info.title;
            this.addForm.virtual_num = info.virtual_num;
            this.addForm.recommended = info.recommended;
            this.addForm.status = info.status;
            this.addForm.sort = info.sort;
            this.addForm.price = info.price;
            this.addForm.image = info.image;
            this.addForm.id = info.id;
          }
        });
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.id ? this.$api.beauty.courseEdit : this.$api.beauty.courseAdd, this.addForm).then(res => {
            if (res.code == 0) {
              this.$message.success(this.id ? '编辑成功' : '保存成功');
              // this.$router.push('/beauty/curriculum/list');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  padding-bottom: 100px;
  .el-form-item {
    width: 800px;
  }
}
</style>